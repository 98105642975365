import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CompDem Key Activities`}</h1>
    <p>{`To accomplish our mission and acheive our vision, we engage in:`}</p>
    <ul>
      <li parentName="ul">{`Researching and developing new methods for data science enabled participatory democracy, as new methods in data science emerge`}</li>
      <li parentName="ul">{`Disseminating best practices for `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` implementations, specifically maintaining the `}<a parentName="li" {...{
          "href": "/knowledge-base",
          "title": "knowledge base"
        }}>{`knowledge base`}</a>{` hosted on this site`}</li>
      <li parentName="ul">{`Supporting engagements using `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` that rise to a high ethical standard, and ensuring the dissemination of open data and results to citizens in a timely manner as appropriate to the situation`}</li>
      <li parentName="ul">{`Supporting, maintaining and improving the `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/open-source",
          "title": "open source"
        }}>{`open source`}</a>{` codebase`}</li>
      <li parentName="ul">{`Building networks of individuals and organizations, and serving as a central point of connection for those interested in the ethical application of data science to democratic methods`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      